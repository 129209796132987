import zhCN from 'antd/es/locale/zh_CN';
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import 'moment/locale/zh-cn';
import moment from 'moment';
import App from './App';
import './index.less';
moment.locale('zh-cn');

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
