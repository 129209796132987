import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { lazy, Suspense } from 'react';
import 'antd/dist/antd.less';
import './App.less';

const Index = lazy(() => import('pages/index'));
const About = lazy(() => import('pages/about'));
const MyApp = lazy(() => import('pages/myApp'));
// const Layout = lazy(() => import('pages/layout'));
interface Props {
  history?: any
}
const App: React.FC<Props> = (props) => {

  return (
    <div>
      <Suspense fallback={<div></div>}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Index} exact={true}></Route>
            <Route path="/app" component={MyApp} exact={true}></Route>
            <Route path="/about" component={About} exact={true}></Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
